import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { MemberApi } from "../../../../../setup/function/Api";
import { KTSVG } from "../../../../../_start/helpers";
import FileSaver from "file-saver";
import { useHistory } from "react-router-dom";

type Props = {
  show: boolean;
  handleClose: () => void;
  id: any;
  //   name: string;
  //   member: any;
};

export default function ModalBulkExport(props: Props) {
  const { show, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const navigation = useHistory();

  // Export Xls File
  console.log("ids", props.id);
  async function ExportFile() {
    const endcodeIds = encodeURIComponent(JSON.stringify(props.id));
    console.log("ids", props.id);
    setLoading(true);
    await MemberApi.BulkExportApi(props.id)
      .then((response) => {
        // console.log("response", response);
        console.log(`response headers: ${response.headers}`);
        const contentType = response.headers["content-type"];
        if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          downloadFile(response);
        } else {
          console.error("Invalid Content-Type header");
        }
        // FileSaver.saveAs(blob, `${props.name}.xlsx`);
        // setMemberData(memberDetail);
      })
      .catch((error) => {
        console.log("error", error.response.data.statusCode);
        const { message } = error;
        console.log("message", message);
        // Handle API error
        if (error.response.data.statusCode === 401) {
          console.log("un auth");
          localStorage.removeItem("persist:token");
          navigation.push("/auth/login");
        } else if (axios.isAxiosError(error) && error) {
          setLoading(false);
          console.error("API error:", message);
          setLoading(false);
          alert(message);
        } else {
          console.error("Other error:", error);
          alert(message);
        }
      });
  }

  function downloadFile(response: any) {
    const filename = `Data calon peserta.xlsx`;
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
    setLoading(false);
    alert("Export file berhasil");
  }

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered h-auto"
      show={show}
      onHide={handleClose}
      //   onEntered={loadStepper}
    >
      <div className="container px-10 py-10 ">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-light-primary"
            onClick={handleClose}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotone/Navigation/Close.svg"
            />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body">
          {/*begin::Stepper */}
          <div
            className="stepper stepper-1 d-flex flex-column flex-xl-row flex-row-fluid "
            id="kt_modal_create_app_stepper"
          >
            {/*begin::Aside */}
            {/*begin::Aside */}

            {/*begin::Content */}
            <div className="d-flex flex-row-fluid justify-content-center">
              {/*begin::Form */}
              <form
                className="pb-5 w-100 w-md-400px w-xl-100"
                noValidate
                id="kt_modal_create_app_form"
              >
                {/*begin::Step 1 */}
                <div className="pb-2 current" data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Heading */}
                    <div className="pb-5 pb-lg-10">
                      <h3 className="fw-bolder text-dark display-7 text-center">
                        Konfirmasi Data Peserta dan Konfirmasi Penerimaan
                        Pembayaran{" "}
                      </h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className="fv-row mb-12">
                      <label className="fs-6 fw-normal text-dark text-center form-label">
                        Mohon konfirmasikan kembali data peserta yang tercantum
                        untuk memastikan kebenarannya.
                      </label>
                    </div>
                    {/*end::Form Group */}

                    {/*begin::Form Group */}

                    {/* end::Section */}
                  </div>
                </div>
                {/*end::Step 5 */}

                {/*begin::Actions */}
                <div className="d-flex justify-content-between pt-2 w-100">
                  <div className="mr-2 w-100">
                    {loading !== true ? (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3 w-100"
                        data-kt-stepper-action="next"
                        onClick={ExportFile}
                      >
                        Export Data{" "}
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-1"
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3 w-100"
                        data-kt-stepper-action="next"
                        // onClick={ExportFile}
                        disabled
                      >
                        Sedang melakukan export data
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-1"
                        />
                      </button>
                    )}
                  </div>
                </div>
                {/*end::Actions */}
              </form>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
      </div>
    </Modal>
  );
}
