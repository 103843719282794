import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap-v5";
import { KTSVG } from "../../../../../_start/helpers";

import SuccessIcon from "../../../../../assets/checkMark.png";

import "./ModalStyle.css";

// import "./SuccessModal.css";

type Props = {
  show: boolean;
  handleClose: () => void;

  //   id: any;
};

export default function SuccesModal(props: Props) {
  const { show, handleClose } = props;

  return (
    <>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog-centered h-auto"
        show={show}
        onHide={handleClose}
        //   onEntered={loadStepper}
      >
        <div className="container px-10 py-10 ">
          <div className="modal-body">
            {/*begin::Stepper */}
            <div
              className="stepper stepper-1 d-flex flex-column "
              id="kt_modal_create_app_stepper"
            >
              {/*begin::Aside */}
              {/*begin::Aside */}
              <img
                src={SuccessIcon}
                alt=""
                style={{ maxWidth: "60px" }}
                className="mb-5  mx-auto"
              />
              {/*begin::Form */}
              <h3 className="text-center mt-3">Sinkronisasi file berhasil</h3>
              <button
                type="submit"
                className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3 w-100 mt-5"
                data-kt-stepper-action="next"
                onClick={handleClose}
              >
                Kembali
                <KTSVG
                  path="/media/icons/duotone/Navigation/Right-2.svg"
                  className="svg-icon-3 ms-1"
                />
              </button>
              {/*begin::Content */}
              {/*end::Content */}
            </div>
            {/* end::Stepper */}
          </div>
        </div>
      </Modal>
    </>
  );
}
