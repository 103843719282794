/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { TablesWidget4 } from "../../../_start/partials/widgets";
import { VerifiedMemberTable } from "../../../_start/partials/widgets/tables/VerifiedMemberTable";
// import { KTSVG } from "../../../../_start/helpers";

export const VerifiedMemberPage: React.FC = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  return (
    <>
      {/* begin::Row */}
      {/* <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-4">
          <StatsWidget3 className="card-stretch mb-5  mb-xxl-8" />
        </div>

        <div className="col-xl-4">
          <StatsWidget4 className="card-stretch mb-5  mb-xxl-8" />
        </div>

        <div className="col-xl-4">
          <StatsWidget5 className="card-stretch mb-5  mb-xxl-8" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}

      {/* end::Row */}

      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-12">
          <VerifiedMemberTable className="card-stretch" />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Modals */}

      {/* end::Modals */}
    </>
  );
};
