/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ModalBulkExport from "../../../../app/modules/general/Registrant/modal/ModalBulkExport";
import ModalBulkImport from "../../../../app/modules/general/Registrant/modal/ModalBulkImport";
import ModalLoading from "../../../../app/modules/general/Registrant/modal/ModalLoading";
import PreviewImportModal from "../../../../app/modules/general/Registrant/modal/ModalPreviewImport";
import SuccessModal from "../../../../app/modules/general/Registrant/modal/ModalSuccess";
import { MemberApi } from "../../../../setup/function/Api";
import { PendingRegistrationsType } from "../../../../setup/models/Model";
import { KTSVG } from "../../../helpers";

type Props = {
  className: string;
  innerPadding?: string;
  color?: string;
};

export interface Registration {
  NIK: string;
  "Nama Lengkap": string;
  "Tempat Lahir": string;
  "Tanggal Lahir": string;
  "Ibu Kandung": string;
  Pekerjaan: string;
  "Lokasi Bekerja": string;
  Program: string;
  "Tgl Mendaftar": string;
  "Alamat e-mail": string;
  "No HP": string;
  "ID BPJS": string;
}

export interface MyComponentProps {
  registrations: Registration[];
  fileErrors: any[]; // Update the type accordingly if needed
}

const TablesWidget4: React.FC<Props> = ({
  className,
  innerPadding = "",
  color = "primary",
}) => {
  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const dataFetchRef = useRef(false);
  const [pendingList, setPendingList] = useState<
    PendingRegistrationsType[] | null
  >(null);
  const [totalMembers, setTotalMembers] = useState(0);
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]);
  const ITEMS_PER_PAGE = 10;

  // Show modal import and export
  const [show, setShow] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showImportPreview, setShowImportPreview] = useState(false);
  const [showImportLoading, setShowImportLoading] = useState(false);
  const [showImportSuccess, setShowImportSuccess] = useState(false);
  const [fileRegistratsioan, setFileRegistratsioan] = useState<File | null>(
    null
  );

  // search state
  const [searchBy, setSearchBy] = useState("name");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchInputTimeoutId, setSearchInputTimeoutId] =
    useState<NodeJS.Timeout | null>(null);
  const goto = useHistory();

  // Function to handle page changes
  const handlePageChange = async (pageNumber: number) => {
    if (pageNumber === currentPage) return;
    setCurrentPage(pageNumber);

    if (searchQuery) {
      await fetchPendingList(searchBy, searchQuery, pageNumber);
    } else {
      await getMemberPendingList(pageNumber);
    }
  };

  const getPageNumbers = () => {
    const totalPageNumbers = Math.min(totalPages, 5);
    const startPage = Math.max(
      1,
      currentPage - Math.floor(totalPageNumbers / 2)
    );
    const endPage = Math.min(totalPages, startPage + totalPageNumbers - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  // Preview State
  const [registrationData, setRegistrationData] = useState<Registration[]>();
  const [fileErrorsData, setFileErrorsData] = useState<any[]>();

  const renderPaginationLinks = () => {
    const pageNumbers = getPageNumbers();

    return pageNumbers.map((pageNumber) => (
      <li
        key={pageNumber}
        className={`page-item ${currentPage === pageNumber ? "active" : ""}`}
      >
        <a
          href="#"
          className="page-link"
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </a>
      </li>
    ));
  };

  async function getMemberPendingList(page: number = 1) {
    console.log("Getting Member with pending status.....");
    await MemberApi.GetPendingRegistratiosApi({
      page,
      pageSize: ITEMS_PER_PAGE,
    })
      .then((response) => {
        const pendingListResponse = response?.data?.data?.registrations;

        setPendingList(pendingListResponse);
        setTotalPages(response.data.meta.totalPages);
        setTotalMembers(response.data.meta.total);
      })
      .catch(async (error) => {
        console.log("error", error.response);
        const { message } = error.response.data;
        console.log("message", message);
        // Handle API error
        if (axios.isAxiosError(error) && error) {
          await localStorage.clear();
          await window.location.reload();
        } else {
          console.error("Other error:", error);
          alert(message);
        }
      });
  }

  async function fetchPendingList(
    searchByParam: string,
    searchQuery: string,
    page: number = 1
  ) {
    try {
      const response = await MemberApi.SearchPendingRegistratiosApi(
        searchByParam,
        searchQuery,
        page
      );
      const pendingListResponse = response?.data?.data?.registrations;
      console.log("pending search", pendingListResponse);
      setPendingList(pendingListResponse);
      setTotalPages(response.data.meta.totalPages);
      setTotalMembers(response.data.meta.total);
    } catch (error: any) {
      console.log("error", error.response);
      await getMemberPendingList();
    }
  }

  useEffect(() => {
    if (dataFetchRef.current) return;
    dataFetchRef.current = true;
    getMemberPendingList();
  }, [searchBy, searchQuery]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (searchInputTimeoutId !== null) clearTimeout(searchInputTimeoutId);

    setSearchQuery(event.target.value);
    const query = event.target.value;

    let searchByParam = "name";
    console.log("query length", query.length);
    if (query.length === 16 && !isNaN(Number(query))) {
      searchByParam = "nik";
    }

    setSearchBy(searchByParam);

    const timeoutId = setTimeout(() => {
      setCurrentPage(1);
      fetchPendingList(searchByParam, query);
    }, 1000);

    setSearchInputTimeoutId(timeoutId);
  };

  useEffect(() => {
    return () => {
      if (searchInputTimeoutId) clearTimeout(searchInputTimeoutId);
    };
  }, [searchInputTimeoutId]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    memberId: number
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedMembers((prevSelectedMembers) => [
        ...prevSelectedMembers,
        memberId,
      ]);
    } else {
      setSelectedMembers((prevSelectedMembers) =>
        prevSelectedMembers.filter((id) => id !== memberId)
      );
    }
  };

  // LOG
  console.log("selected member id", selectedMembers);
  console.log("REGIS IMPORT DATA", registrationData);
  console.log("error IMPORT DATA", fileErrorsData);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark">
            Pendaftar BPJS Perisai
          </span>
          <span className="text-muted mt-3 fw-bold fs-7">
            Jumlah pendaftar &nbsp;{" "}
            <strong className="text-dark">{totalMembers}</strong> peserta
          </span>
        </h3>
        <form>
          <div
            className="input-group input-group-solid"
            id="kt_chat_aside_search"
          >
            <span className="input-group-text" id="basic-addon1">
              <KTSVG
                className="svg-icon-1 svg-icon-dark"
                path="/media/icons/duotone/General/Search.svg"
              />
            </span>
            <input
              type="text"
              className="form-control ps-0 py-4 h-auto"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </form>
        <div className="detail_informasi d-flex justify-content-end gap-3">
          {selectedMembers.length > 0 && (
            <a
              href="#"
              className="btn btn-primary"
              onClick={() => setShow(true)}
              style={{ maxHeight: "43px" }}
              // disabled={isExportDisabled}
            >
              Export Excel
            </a>
          )}

          <a
            href="#"
            className="btn btn-outline btn-outline-primary btn-active-light-primary"
            onClick={() => setShowImport(true)}
            style={{ maxHeight: "43px" }}
          >
            Import Excel
          </a>
        </div>
      </div>
      {/* end::Header*/}

      {/* begin::Body*/}
      <div className="card-body py-0">
        {/* begin::Table*/}
        <div className="table-responsive">
          <table
            className="table align-middle border-gray-100"
            id="kt_advance_table_widget_4"
          >
            <thead>
              <tr className="text-start text-muted fw-bolder text-gray-400 text-uppercase fs-7 border-gray-100 border-bottom-1">
                <th className="ps-0 min-w-50px py-5"></th>{" "}
                {/* Checkbox column */}
                <th className="ps-0 min-w-50px py-5">No.</th>{" "}
                {/* Number order column */}
                <th className="ps-0 min-w-250px py-5">NIK</th>
                <th className="ps-0 min-w-250px py-5">Nama</th>
                <th className="min-w-100px py-5">
                  <span className={`text-${color}`}>Tanggal Mendaftar</span>
                  <KTSVG
                    className={`svg-icon-sm svg-icon-${color}`}
                    path="/media/icons/duotone/Navigation/Down-2.svg"
                  />
                </th>
                <th className="min-w-100px py-5">Program</th>
                <th className="min-w-100px pe-0 text-end py-5">Action</th>
              </tr>
            </thead>
            <tbody>
              {pendingList &&
                pendingList.map((member, index) => (
                  <tr key={member.id}>
                    <td className="ps-0">
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          handleCheckboxChange(event, member?.id)
                        }
                        checked={selectedMembers.includes(member?.id)}
                      />{" "}
                      {/* Checkbox */}
                    </td>
                    <td className="ps-0">
                      <span className="text-gray-800 fw-bolder fs-6">
                        {ITEMS_PER_PAGE * (currentPage - 1) + (index + 1)}
                      </span>{" "}
                      {/* Number order */}
                    </td>
                    <td className="ps-0">
                      <a
                        href="#"
                        className="text-gray-800 fw-bolder text-hover-primary fs-6"
                      >
                        {member.nik}
                      </a>
                    </td>
                    <td className="ps-0">
                      <a
                        href="#"
                        className="text-gray-800 fw-bolder text-hover-primary fs-6"
                      >
                        {member.name}
                      </a>
                    </td>
                    <td>
                      <span className={`text-${color} fw-bolder d-block fs-6`}>
                        {member.date}
                      </span>
                    </td>
                    <td>
                      <span className="label label-lg label-light-primary label-inline">
                        {member.program}
                      </span>
                    </td>
                    <td className="pe-0 text-end">
                      <a
                        href={`/detail-peserta/${member.id}`}
                        className="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm mx-3"
                      >
                        <i className="bi bi-eye-fill"></i>
                      </a>
                      {/* <a
                        href="#"
                        className="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm"
                      >
                        <KTSVG
                          className="svg-icon-4"
                          path="/media/icons/duotone/General/Trash.svg"
                        />
                      </a> */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <ul className="pagination mb-6">
          {/* Previous page */}
          <li
            className={`page-item previous ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <i className="previous"></i>
            </a>
          </li>
          {/* Page numbers */}
          {renderPaginationLinks()}
          {/* Next page */}
          <li
            className={`page-item next ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <i className="next"></i>
            </a>
          </li>
        </ul>
        {/* end::Table*/}
      </div>
      {/* end::Body*/}
      <ModalBulkExport
        show={show}
        id={selectedMembers}
        handleClose={() => setShow(false)}
      />
      <ModalBulkImport
        show={showImport}
        handleClose={() => setShowImport(false)}
        id={selectedMembers}
        setShowImportPreview={setShowImportPreview}
        setRegistrationData={setRegistrationData}
        setFileErrorsData={setFileErrorsData}
        setFileRegistratsioan={setFileRegistratsioan}
      />
      <PreviewImportModal
        show={showImportPreview}
        id={selectedMembers}
        handleClose={() => setShowImportPreview(false)}
        setShowImportLoading={setShowImportLoading}
        setShowImportSuccess={setShowImportSuccess}
        registrationData={registrationData}
        fileErrorsData={fileErrorsData}
        file={fileRegistratsioan}
      />
      <ModalLoading
        show={showImportLoading}
        handleClose={() => setShowImportLoading(false)}
        setShowImportSuccess={setShowImportSuccess}
        setShowImportPreview={setShowImportPreview}
        setShow={setShow}
      />
      <SuccessModal
        show={showImportSuccess}
        handleClose={() => setShowImportSuccess(false)}
      />
    </div>
  );
};

export { TablesWidget4 };
