import { toAbsoluteUrl } from "../../../helpers";
import Logo from "../../../../assets/baliolaLoo.png";

export function FallbackView() {
  return (
    <div className="splash-screen">
      <img src={Logo} />
      <span>Loading ...</span>
    </div>
  );
}
