/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ModalBulkExport from "../../../../app/modules/general/Registrant/modal/ModalBulkExport";
import ModalBulkImport from "../../../../app/modules/general/Registrant/modal/ModalBulkImport";
import ModalLoading from "../../../../app/modules/general/Registrant/modal/ModalLoading";
import PreviewImportModal from "../../../../app/modules/general/Registrant/modal/ModalPreviewImport";
import SuccessModal from "../../../../app/modules/general/Registrant/modal/ModalSuccess";
import { MemberApi } from "../../../../setup/function/Api";
import {
  PendingRegistrationsType,
  VerifiedMemberType,
} from "../../../../setup/models/Model";
import { KTSVG } from "../../../helpers";

type Props = {
  className: string;
  innerPadding?: string;
  color?: string;
};

export interface Registration {
  NIK: string;
  "Nama Lengkap": string;
  "Tempat Lahir": string;
  "Tanggal Lahir": string;
  "Ibu Kandung": string;
  Pekerjaan: string;
  "Lokasi Bekerja": string;
  Program: string;
  "Tgl Mendaftar": string;
  "Alamat e-mail": string;
  "No HP": string;
  "ID BPJS": string;
}

export interface MyComponentProps {
  registrations: Registration[];
  fileErrors: any[]; // Update the type accordingly if needed
}

const VerifiedMemberTable: React.FC<Props> = ({
  className,
  innerPadding = "",
  color = "primary",
}) => {
  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const dataFetchRef = useRef(false);
  const [memberList, setmemberList] = useState<VerifiedMemberType[] | null>(
    null
  );
  const [totalMembers, setTotalMembers] = useState(0);
  const ITEMS_PER_PAGE = 10;

  // Show modal import and export

  // search state
  const [searchBy, setSearchBy] = useState("name");
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle page changes
  const handlePageChange = async (pageNumber: number) => {
    if (pageNumber === currentPage) return;
    setCurrentPage(pageNumber);
    await getMemberList(pageNumber);
  };

  // Preview State

  const getPageNumbers = () => {
    const totalPageNumbers = Math.min(totalPages, 5);
    const startPage = Math.max(1, currentPage - Math.floor(totalPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + totalPageNumbers - 1);

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };

  const renderPaginationLinks = () => {
    const pageNumbers = getPageNumbers();

    return pageNumbers.map((pageNumber) => (
      <li
        key={pageNumber}
        className={`page-item ${currentPage === pageNumber ? "active" : ""}`}
      >
        <a
          href="#"
          className="page-link"
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </a>
      </li>
    ));
  };

  async function getMemberList(page: number = 1) {
    console.log("Getting Member with pending status.....");
    await MemberApi.GetVerifiedMemberApi({ page, pageSize: ITEMS_PER_PAGE })
      .then((response) => {
        const memberListResponse = response?.data?.data?.registrations;
        // console.log("response", response);
        setmemberList(memberListResponse);
        setTotalPages(response.data.meta.totalPages);
        setTotalMembers(response.data.meta.total);
      })
      .catch((error) => {
        console.log("error", error.response);
        const { message } = error.response.data;
        console.log("message", message);
        // Handle API error
        if (axios.isAxiosError(error) && error) {
          console.error("API error:", message);
          alert(message);
        } else {
          console.error("Other error:", error);
          alert(message);
        }
      });
  }

  async function fetchMemberList(searchByParam: string, searchQuery: string) {
    try {
      const response = await MemberApi.SearchPendingRegistratiosApi(
        searchByParam,
        searchQuery
      );
      const memberListResponse = response?.data?.data?.registrations;
      console.log("pending search", memberListResponse);
      setmemberList(memberListResponse);
    } catch (error: any) {
      console.log("error", error.response);
    }
  }

  useEffect(() => {
    if (dataFetchRef.current) return;
    dataFetchRef.current = true;
    getMemberList();
  }, [searchBy, searchQuery]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
    const query = event.target.value;

    let searchByParam = "name";
    console.log("query length", query.length);
    if (query.length === 16 && !isNaN(Number(query))) {
      searchByParam = "nik";
    }
    fetchMemberList(searchByParam, query);
  };

  // LOG

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark">
            Tabel Peserta BPJS
          </span>
          {/* <span className="text-muted mt-3 fw-bold fs-7">
            Jumlah peserta &nbsp;{" "}
            <strong className="text-dark">{memberList?.length}</strong> orang
          </span> */}
        </h3>
        <form>
          <div
            className="input-group input-group-solid"
            id="kt_chat_aside_search"
          >
            {/* <span className="input-group-text" id="basic-addon1">
              <KTSVG
                className="svg-icon-1 svg-icon-dark"
                path="/media/icons/duotone/General/Search.svg"
              />
            </span> */}
            {/* <input
              type="text"
              className="form-control ps-0 py-4 h-auto"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
            /> */}
          </div>
        </form>
      </div>
      {/* end::Header*/}

      {/* begin::Body*/}
      <div className="card-body py-0">
        {/* begin::Table*/}
        <div className="table-responsive">
          <table
            className="table align-middle border-gray-100"
            id="kt_advance_table_widget_4"
          >
            <thead>
              <tr className="text-start text-muted fw-bolder text-gray-400 text-uppercase fs-7 border-gray-100 border-bottom-1">
                {/* <th className="ps-0 min-w-50px py-5"></th>{" "} */}
                {/* Checkbox column */}
                <th className="ps-0 min-w-20px py-5">No.</th>{" "}
                {/* Number order column */}
                <th className="ps-0 min-w-250px py-5">NIK</th>
                <th className="ps-0 min-w-250px py-5">ID BPJS</th>
                <th className="ps-0 min-w-200px py-5">Nama</th>
                <th className="min-w-150px py-5">
                  <span className={`text-${color}`}>Tanggal Mendaftar</span>
                  <KTSVG
                    className={`svg-icon-sm svg-icon-${color}`}
                    path="/media/icons/duotone/Navigation/Down-2.svg"
                  />
                </th>
                <th className="min-w-100px py-5">Program</th>
                {/* <th className="min-w-100px pe-0 text-end py-5">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {memberList &&
                memberList.map((member, index) => {
                  return (
                    <tr key={member.id}>
                      <td className="ps-0">
                        <span className="text-gray-800 fw-bolder fs-6">
                          {/* {index + 1} */}
                          {ITEMS_PER_PAGE * (currentPage - 1) + (index + 1)}
                        </span>{" "}
                        {/* Number order */}
                      </td>
                      <td className="ps-0">
                        <a
                          href={`/detail-peserta/${member.id}`}
                          className="text-gray-800 fw-bolder text-hover-primary fs-6"
                        >
                          {member.nik}
                        </a>
                      </td>
                      <td className="ps-0">
                        <a
                          href={`/detail-peserta/${member.id}`}
                          className="text-gray-800 fw-bolder text-hover-primary fs-6"
                        >
                          {member?.idBpjs}
                        </a>
                      </td>
                      <td className="ps-0">
                        <a
                          href="#"
                          className="text-gray-800 fw-bolder text-hover-primary fs-6"
                        >
                          {member.name}
                        </a>
                      </td>
                      <td>
                        <span
                          className={`text-${color} fw-bolder d-block fs-6`}
                        >
                          {member.date}
                        </span>
                      </td>
                      <td>
                        <span className="label label-lg label-light-primary label-inline">
                          {member.program}
                        </span>
                      </td>
                      {/* <td className="pe-0 text-end">
                      <a
                        href={`/detail-peserta/${member.id}`}
                        className="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm mx-3"
                      >
                        <KTSVG
                          className="svg-icon-4"
                          path="/media/icons/duotone/Interface/Edit.svg"
                        />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-bg-light btn-color-muted btn-active-color-primary btn-sm"
                      >
                        <KTSVG
                          className="svg-icon-4"
                          path="/media/icons/duotone/General/Trash.svg"
                        />
                      </a>
                    </td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <ul className="pagination mb-6 mt-0">
          {/* Previous page */}
          <li
            className={`page-item previous ${currentPage === 1 ? "disabled" : ""
              }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <i className="previous"></i>
            </a>
          </li>
          {/* Page numbers */}
          {renderPaginationLinks()}
          {/* Next page */}
          <li
            className={`page-item next ${currentPage === totalPages ? "disabled" : ""
              }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <i className="next"></i>
            </a>
          </li>
        </ul>
        {/* end::Table*/}
      </div>
      {/* end::Body*/}
    </div>
  );
};

export { VerifiedMemberTable };
