import axios from "axios";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { Form, Modal } from "react-bootstrap-v5";
import { MemberApi } from "../../../../../setup/function/Api";
import { KTSVG } from "../../../../../_start/helpers";
import {
  Registration,
  TablesWidget1,
  TablesWidget2,
  TablesWidget3,
  TablesWidget4,
} from "../../../../../_start/partials/widgets";
import { TablePreview } from "../../../../../_start/partials/widgets/tables/PreviewTable";
import ModalLoading from "./ModalLoading";

type Props = {
  show: boolean;
  handleClose: () => void;
  id: any;
  setShowImportLoading: (show: boolean) => void;
  setShowImportSuccess: (show: boolean) => void;
  registrationData: Registration[] | undefined;
  fileErrorsData: any;
  file: any;
};

export default function PreviewImportModal(props: Props) {
  const {
    show,
    handleClose,
    setShowImportLoading,
    registrationData,
    fileErrorsData,
    file,
    setShowImportSuccess,
  } = props;
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {};

  async function importFile() {
    setShowImportLoading(true);
    handleClose();

    console.log("running import file func...", file);

    await MemberApi.BulkImportApi(file as File)
      .then((response) => {
        console.log("response");
        setShowImportLoading(false);

        setShowImportSuccess(true);

        // alert("Success mengimport file");
      })
      .catch((error) => {
        setShowImportLoading(false);

        console.log("error", error.response);
        const { message } = error.response.data;
        console.log("message", message);
        // Handle API error
        if (axios.isAxiosError(error) && error) {
          setLoading(false);
          setShowImportLoading(false);

          console.error("API error:", message);
          setLoading(false);
          setShowImportLoading(false);

          alert(message);
        } else {
          console.error("Other error:", error);
          setShowImportLoading(false);

          alert(message);
        }
      });
  }

  const handleSubmit = () => {
    importFile();
  };

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-2}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered h-auto"
      show={show}
      onHide={handleClose}
      size={"xl"}
      //   onEntered={loadStepper}
    >
      <div className="container px-10 py-10 ">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-light-primary"
            onClick={handleClose}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotone/Navigation/Close.svg"
            />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body">
          {/*begin::Stepper */}
          <div
            className="stepper stepper-1 d-flex flex-column flex-xl-row flex-row-fluid "
            id="kt_modal_create_app_stepper"
          >
            {/*begin::Aside */}
            {/*begin::Aside */}

            {/*begin::Content */}
            <div className="d-flex flex-row-fluid justify-content-center">
              {/*begin::Form */}
              <Form
                className="pb-5 w-100 w-md-400px w-xl-100"
                // onSubmit={handleSubmit}
                id="kt_modal_create_app_form"
              >
                {/*begin::Step 1 */}
                <div className="pb-2 current" data-kt-stepper-element="content">
                  <TablePreview
                    className=""
                    registrationData={registrationData}
                    fileErrorsData={fileErrorsData}
                  />
                </div>
                {/*end::Step 5 */}

                {/*begin::Actions */}
                <div className="d-flex justify-content-end pt-2 w-100">
                  <div className="mr-2 w-50 d-flex flex-row">
                    <button
                      type="submit"
                      className="btn btn-lg btn-danger fw-bolder py-4 ps-8 me-3 w-100"
                      data-kt-stepper-action="next"
                    >
                      Tolak{" "}
                      <KTSVG
                        path="/media/icons/duotone/Navigation/Right-2.svg"
                        className="svg-icon-3 ms-1"
                      />
                    </button>
                    {loading !== true ? (
                      <button
                        type="button"
                        className={`btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3 w-100 ${
                          fileErrorsData?.length > 0 ? "disabled" : ""
                        }`}
                        data-kt-stepper-action="next"
                        onClick={handleSubmit}
                      >
                        Simpan{" "}
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-1"
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3 w-100"
                        data-kt-stepper-action="next"
                        disabled
                      >
                        Sedang melakukan penyimpanan.....
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-1"
                        />
                      </button>
                    )}
                  </div>
                </div>
                {/*end::Actions */}
              </Form>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* <ModalLoading
            show={loadingModal}
            setLoadingModal={() => setLoadingModal(false)}
          /> */}
          {/* end::Stepper */}
        </div>
      </div>
    </Modal>
  );
}
