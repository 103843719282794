/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ModalBulkExport from "../../../../app/modules/general/Registrant/modal/ModalBulkExport";
import ModalBulkImport from "../../../../app/modules/general/Registrant/modal/ModalBulkImport";
import { MemberApi } from "../../../../setup/function/Api";
import { PendingRegistrationsType } from "../../../../setup/models/Model";
import { KTSVG } from "../../../helpers";
import { Registration } from "./TablesWidget4";

type Props = {
  className: string;
  innerPadding?: string;
  color?: string;
  registrationData: Registration[] | undefined;
  fileErrorsData: any;
};

const TablePreview: React.FC<Props> = ({
  className,
  innerPadding = "",
  color = "primary",
  registrationData,
  fileErrorsData,
}) => {
  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const dataFetchRef = useRef(false);
  const [pendingList, setPendingList] = useState<
    PendingRegistrationsType[] | null
  >(null);
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]);

  // Show modal import and export
  const [show, setShow] = useState(false);
  const [showImport, setShowImport] = useState(false);

  const dataWithoutIDBPJS = registrationData?.filter(
    (registration) => !registration["ID BPJS"]
  );
  const countWithoutIDBPJS = dataWithoutIDBPJS?.length;

  // search state

  // Pagination logic

  async function getMemberPendingList() {
    console.log("Getting Member with pending status.....");
    await MemberApi.GetPendingRegistratiosApi()
      .then((response) => {
        const pendingListResponse = response?.data?.data?.registrations;
        // console.log("response", response);
        setPendingList(pendingListResponse);
      })
      .catch((error) => {
        console.log("error", error.response);
        const { message } = error.response.data;
        console.log("message", message);
        // Handle API error
        if (axios.isAxiosError(error) && error) {
          console.error("API error:", message);
          alert(message);
        } else {
          console.error("Other error:", error);
          alert(message);
        }
      });
  }

  useEffect(() => {
    if (dataFetchRef.current) return;
    dataFetchRef.current = true;
    // getMemberPendingList();
  }, []);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {};

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    memberId: number
  ) => {};

  console.log("selected member id", selectedMembers);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header align-items-center justify-content-center  border-0 py-5">
        <h3 className="card-title flex-column align-items-center">
          <span className="card-label fw-bolder text-dark">
            Tabel data peserta
          </span>
          <span className="text-muted mt-3 fw-bold fs-7">
            Jumlah peserta yang tidak memiliki ID BPJS &nbsp;{" "}
            <strong className="text-dark">{countWithoutIDBPJS}</strong> &nbsp;
            peserta dari <strong>{registrationData?.length}</strong>
          </span>
        </h3>
      </div>
      {/* end::Header*/}

      {/* begin::Body*/}
      <div className="card-body py-0">
        {/* begin::Table*/}
        <div className="table-responsive">
          <table
            className="table align-middle border-gray-100"
            id="kt_advance_table_widget_4"
          >
            <thead>
              <tr className="text-start text-muted fw-bolder text-gray-400 text-uppercase fs-7 border-gray-100 border-bottom-1">
                {/* Checkbox column */}
                <th className="ps-0 min-w-50px py-5">No.</th>{" "}
                {/* Number order column */}
                <th className="ps-0 min-w-250px py-5">NIK</th>
                <th className="ps-0 min-w-250px py-5">Nama</th>
                <th className="min-w-100px py-5">
                  <span className={`text-${color}`}>Tanggal Mendaftar</span>
                </th>
                <th className="min-w-100px py-5">Program</th>
                <th className="min-w-100px pe-0 text-end py-5">BPJS ID</th>
              </tr>
            </thead>
            <tbody>
              {registrationData &&
                registrationData.map((member, index) => (
                  <tr key={member.NIK}>
                    <td className="ps-0">
                      <span className="text-gray-800 fw-bolder fs-6">
                        {index + 1}
                      </span>{" "}
                      {/* Number order */}
                    </td>
                    <td className="ps-0">
                      <a
                        href="#"
                        className="text-gray-800 fw-bolder text-hover-primary fs-6"
                      >
                        {member.NIK}
                      </a>
                    </td>
                    <td className="ps-0">
                      <a
                        href="#"
                        className="text-gray-800 fw-bolder text-hover-primary fs-6"
                      >
                        {member["Nama Lengkap"]}
                      </a>
                    </td>
                    <td>
                      <span className={`text-${color} fw-bolder d-block fs-6`}>
                        {member["Tgl Mendaftar"]}
                      </span>
                    </td>
                    <td>
                      <span className="label label-lg label-light-primary label-inline">
                        {member.Program}
                      </span>
                    </td>
                    <td className="pe-0 text-end">
                      <span className="label label-lg label-light-primary label-inline">
                        {member["ID BPJS"]}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}

        {fileErrorsData.length > 0 && (
          <p className="text-danger w-100 fs-4 fw-bolder">Peringatan:</p>
        )}

        <ul>
          {fileErrorsData.length > 0 &&
            fileErrorsData.map((error: string, index: number) => (
              <li key={index} className="text-danger w-100">
                {error}
              </li>
            ))}
        </ul>

        {/* end::Table*/}
      </div>
      {/* end::Body*/}
    </div>
  );
};

export { TablePreview };
