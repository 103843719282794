import axios from "axios";
import { config } from "../config/Config";
import { getToken } from "../../app/modules/auth/redux/AuthCRUD";

const PENDING_REGISTRATION_ENDPOINT = "/backoffice-pending-registrations";
const VERIFIED_MEMBER_ENDPOINT = "/backoffice-verified-registrations";

const DETAIL_MEMBER_ENDPOINT = "/member-registrations/"; // need id
const EXPORT_XLS_ENDPOINT = "/export/registrations/"; // need id
const IMPORT_XLS_ENDPOINT = "/import/registrations/"; // need id
const BULK_EXPORT_XLS_ENDPOINT = "/bulk-export/registrations"; // need id
const PREVIEW_IMPORT_XLS_ENDPOINT = "/bulk-import/mapping-preview"; // need id
const BULK_IMPORT_XLS_ENDPOINT = "/bulk-import/registrations"; // need id

const API_URL = config.API_URL_STAGING;
export class MemberApi {
  public static async GetPendingRegistratiosApi(params?: {
    page: number;
    pageSize: number;
  }) {
    const token = getToken();
    const invalidToken = "1020120128";
    return await axios.get(`${API_URL}${PENDING_REGISTRATION_ENDPOINT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
  }
  public static async GetVerifiedMemberApi(params?: {
    page: number;
    pageSize: number;
  }) {
    const token = getToken();
    const invalidToken = "1020120128";
    return await axios.get(`${API_URL}${VERIFIED_MEMBER_ENDPOINT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
  }

  public static async SearchPendingRegistratiosApi(
    searchBy: string,
    query: string,
    page?: number,
    pageSize?: number
  ) {
    const token = getToken();
    const params = {};

    if (searchBy === "name") {
      params["name"] = query;
    } else if (searchBy === "nik") {
      params["nik"] = query;
    }

    params["page"] = page;
    params["pageSize"] = pageSize;

    console.log("params", params);
    return await axios.get(`${API_URL}${PENDING_REGISTRATION_ENDPOINT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
  }

  public static async GetDetailApplicantsApi(id: number) {
    return await axios.get(`${API_URL}${DETAIL_MEMBER_ENDPOINT}${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public static async ExportApi(id: number) {
    return await axios.get(`${API_URL}${EXPORT_XLS_ENDPOINT}${id}`, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  public static async BulkExportApi(id: number[]) {
    const data = {
      registrationIds: id,
    };
    return await axios.post(`${API_URL}${BULK_EXPORT_XLS_ENDPOINT}`, data, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public static async ImportApi(id: number, file: File) {
    const formData = new FormData();

    formData.append("file", file);
    return await axios.post(`${API_URL}${IMPORT_XLS_ENDPOINT}${id}`, formData, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "content-type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    });
  }
  public static async PreviewImportApi(file: File) {
    const formData = new FormData();

    formData.append("file", file);
    return await axios.post(
      `${API_URL}${PREVIEW_IMPORT_XLS_ENDPOINT}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "content-type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );
  }
  public static async BulkImportApi(file: File) {
    const formData = new FormData();

    const token = await getToken();

    formData.append("file", file);
    console.log("file FROM API", file);
    // console.log("token", getToken());
    console.log("file FROM API", formData);

    const response = await axios.post(
      `${API_URL}${BULK_IMPORT_XLS_ENDPOINT}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );
    console.log("resp", response);
    return response;
  }
  // to handle rejections
  public static async RejectRegistrationsApi(id: number, reason: string) {
    const response = await axios.post(
      `${API_URL}${DETAIL_MEMBER_ENDPOINT}${id}/reject`,
      {
        reason: reason,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response;
  }
}
