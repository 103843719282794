import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap-v5";
import { useHistory } from "react-router-dom";
import { KTSVG } from "../../../../../_start/helpers";

import XIcon from "../../../../../assets/selfi.png";

import "./ModalStyle.css";
import { MemberApi } from "../../../../../setup/function/Api";
import axios from "axios";

// import "./SuccessModal.css";

type Props = {
  id: number;
  show: boolean;
  handleClose: () => void;
};

export default function RejectModal(props: Props) {
  const { show, handleClose } = props;
  const [reason, setReason] = useState("");
  const history = useHistory();
  // to handle "Tolak" button inside modal
  const handleReject = async () => {
    try {
      await MemberApi.RejectRegistrationsApi(props.id, reason);
      alert("Pendaftaran berhasil ditolak");
      // go to dashboard
      history.push("/dashboard");
    } catch (error: any) {
      console.log("API Error: ", error.response);
      const { message } = error.response.data;
      console.log("API Error Message:", message);

      // Handle API error
      if (axios.isAxiosError(error) && error) {
        console.error("API error:", message);
        // await localStorage.clear();
        // await window.location.reload();
      } else {
        console.error("Other error:", error);
        alert(message);
      }
    }
  };

  return (
    <>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog-centered h-auto"
        show={show}
        onHide={handleClose}
        //   onEntered={loadStepper}
      >
        <div className="container px-10 py-10 ">
          <div className="modal-body">
            {/*begin::Stepper */}
            <div
              className="stepper stepper-1 d-flex flex-column "
              id="kt_modal_create_app_stepper"
            >
              {/*begin::Aside */}
              {/*begin::Aside */}
              <img
                src={XIcon}
                alt=""
                style={{ maxWidth: "60px" }}
                className="mb-5  mx-auto"
              />
              {/*begin::Form */}
              <h3 className="text-center mt-3">Tolak Pendaftaran</h3>
              <p className="text-center mt-3">
                Apakah anda yakin ingin menolak pendaftaran ini?
              </p>
              <div className="d-flex flex-column mb-5">
                <label className="fs-6 fw-bold mb-2">Alasan Penolakan</label>
                <textarea
                  className="form-control form-control-solid mb-3"
                  rows={3}
                  placeholder="Alasan Penolakan"
                  name="reason"
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </div>
              {/*end::Form */}
              <button
                type="submit"
                className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3 w-100 mt-5"
                data-kt-stepper-action="next"
                onClick={handleClose}
              >
                Kembali
                <KTSVG
                  path="/media/icons/duotone/Navigation/Right-2.svg"
                  className="svg-icon-3 ms-1"
                />
              </button>
              <button
                type="submit"
                className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3 w-100 mt-5"
                data-kt-stepper-action="next"
                onClick={handleReject}
              >
                Tolak
                <i className="fas fa-times ms-3 mb-1"></i>
              </button>
              {/*begin::Content */}
              {/*end::Content */}
            </div>
            {/* end::Stepper */}
          </div>
        </div>
      </Modal>
    </>
  );
}
