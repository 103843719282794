import React from "react";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  return (
    <>
      {" "}
      <>
        <>
          <div className="menu-item">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              Dashboards
            </h4>
          </div>
          <AsideMenuItem to="/dashboard" title="Menunggu verifikasi" />
          <AsideMenuItem to="/detail-peserta-verified" title="Peserta" />
        </>
      </>
    </>
  );
}
