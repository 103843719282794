import React, { useEffect, useRef, useState } from "react";

import DummyKtp from "../../../../assets/dummyKTP.png";
import Loader from "../../../../assets/loader2.gif";
import DummyProof from "../../../../assets/bukti.png";
import ModalConfirmation from "./modal/ModalConfirmation";
import ModalReject from "./modal/ModalReject";
import ModalImport from "./modal/ModalImport";
import { useParams } from "react-router-dom";
import { MemberApi } from "../../../../setup/function/Api";
import { DetailMember } from "../../../../setup/models/Model";
import axios from "axios";

import "./Detail.css";

export default function DetailRegistrant() {
  const [show, setShow] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [memberData, setMemberData] = useState<DetailMember>();

  const dataFetchRef = useRef(false);

  // Get params as member id
  const getParams: any = useParams();
  const memberId = getParams?.id;

  // HIT GET DETAIL MEMBER API
  async function getMemberDetail() {
    await MemberApi.GetDetailApplicantsApi(memberId)
      .then((response) => {
        const memberDetail = response?.data?.data?.registration;
        // console.log("response", response);
        console.log("member data", memberDetail);
        setMemberData(memberDetail);
      })
      .catch((error) => {
        console.log("error", error.response);
        const { message } = error.response.data;
        console.log("message", message);
        // Handle API error
        if (axios.isAxiosError(error) && error) {
          console.error("API error:", message);
          alert(message);
        } else {
          console.error("Other error:", error);
          alert(message);
        }
      });
  }

  // useEffect
  useEffect(() => {
    if (dataFetchRef.current) return;
    dataFetchRef.current = true;
    getMemberDetail();
  }, []);

  return (
    <div className="d-flex flex-column flex-md-column gap-4">
      {/*begin::Aside */}
      <div className="flex-column flex-md-row-fluid">
        {/*begin::List Widget 2*/}
        <div className="card card-custom mb-10 mb-md-0">
          {/*begin::Body*/}
          <div className="card-body py-10 px-6">
            <p className="fs-2 ms-2 fw-bolder">Informasi Peserta</p>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> NIK:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.nik : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> Nama Lengkap:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.fullName : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> Tangal Lahir:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.birthdate : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> Tempat Lahir:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.birthplace : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> Nama Ibu Kandung:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.motherName : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3">Nomor Whatsaap:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.phone : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3">Email:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.email : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3">Pekerjaan:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.occupation : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3">Lokasi Pekerjaan:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.workLocation : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-end gap-3">
              <a
                href="#"
                className="btn btn-primary"
                onClick={() => setShow(true)}
              >
                Export Excel
              </a>
              <a
                href="#"
                className="btn btn-outline  btn-outline-primary btn-active-light-primary"
                onClick={() => setShowImport(true)}
              >
                Import Excel
              </a>
              <a
                href="#"
                className="btn btn-danger btn-active-light-primary"
                onClick={() => setShowReject(true)}
              >
                Tolak
              </a>
            </div>

            {/*end::Theme List*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::List Widget 2*/}
      </div>
      <div className="flex-column flex-md-row-fluid">
        {/*begin::List Widget 2*/}
        <div className="card card-custom mb-10 mb-md-0">
          {/*begin::Body*/}
          <div className="card-body py-10 px-6">
            <p className="fs-2 ms-2 fw-bolder">Informasi Registrasi</p>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> Tanggal Registrasi:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.registrationDate : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> Nama Paket:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.program : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> Harga Paket:</p>
              <p className="fs-5 px-3">
                {" "}
                Rp {memberData ? memberData.price : "Loading..."}
              </p>
            </div>
            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> Metode Pembayaran:</p>

              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.paymentMethod : "Loading..."}
              </p>
            </div>

            <div className="detail_informasi d-flex justify-content-between">
              <p className="fs-5 px-3"> Tanggal Pembayaran:</p>
              <p className="fs-5 px-3">
                {" "}
                {memberData ? memberData.paidAt : "Loading..."}
              </p>
            </div>

            {/*end::Theme List*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::List Widget 2*/}
      </div>
      {/*end::Aside*/}
      {/*begin::Layout*/}
      <div className="flex-md-row-fluid">
        {/*begin::Card*/}
        <div className="card card-custom">
          <div className="card-body py-10">
            <h2 className="text-dark fw-bolder fs-1 mb-5">Foto KTP</h2>
            {memberData?.ktpUrl ? (
              <div className="photo_file">
                <img
                  src={memberData?.ktpUrl}
                  alt=""
                  className="w-100"
                  style={{ maxHeight: "450px" }}
                />
              </div>
            ) : (
              <div
                className="photo_file bg-light d-flex justify-content-center align-items-center loading-div"
                style={{ maxHeight: "450px", height: "450px" }}
              >
                <img
                  src={Loader}
                  alt="Loading..."
                  className=""
                  style={{ maxHeight: "128px" }}
                />
              </div>
            )}

            {/*begin::Accordion*/}

            {/*end::Accordion*/}
          </div>
          <div className="card-body py-10">
            <h2 className="text-dark fw-bolder fs-1 mb-5">Foto Selfie KTP</h2>
            {memberData?.selfieKtpUrl ? (
              <div className="photo_file ">
                <img
                  src={memberData?.selfieKtpUrl}
                  alt=""
                  className="w-100"
                  style={{ maxHeight: "450px" }}
                />
              </div>
            ) : (
              <div
                className="photo_file bg-light d-flex justify-content-center align-items-center loading-div"
                style={{ maxHeight: "450px", height: "450px" }}
              >
                <img
                  src={Loader}
                  alt="Loading..."
                  className=""
                  style={{ maxHeight: "128px" }}
                />
              </div>
            )}

            {/*begin::Accordion*/}

            {/*end::Accordion*/}
          </div>
          <div className="card-body py-10">
            <h2 className="text-dark fw-bolder fs-1 mb-5">Bukti Pembayaran</h2>

            {memberData?.paymentProof ? (
              <div className="photo_file ">
                <img
                  src={memberData?.paymentProof}
                  alt=""
                  className="w-50"
                  style={{ maxHeight: "450px" }}
                />
              </div>
            ) : (
              <div
                className="photo_file bg-light d-flex justify-content-center align-items-center loading-div"
                style={{ maxHeight: "450px", height: "450px" }}
              >
                <img
                  src={Loader}
                  alt="Loading..."
                  className=""
                  style={{ maxHeight: "128px" }}
                />
              </div>
            )}

            {/*begin::Accordion*/}

            {/*end::Accordion*/}
          </div>
        </div>
        {/*end::Card*/}
        <ModalConfirmation
          show={show}
          handleClose={() => setShow(false)}
          id={memberId}
          name={
            memberData?.fullName ? (memberData?.fullName as string) : "file"
          }
          member={memberData}
        />
        <ModalImport
          show={showImport}
          handleClose={() => setShowImport(false)}
          id={memberId}
        />
        <ModalReject
          show={showReject}
          handleClose={() => setShowReject(false)}
          id={memberId}
        />
      </div>
      {/*end::Layout*/}
    </div>
  );
}
