import React from "react";
import DetailRegistrant from "../../modules/general/Registrant/DetailRegistrant";

export default function DetailRegistrantPage() {
  return (
    <div>
      <DetailRegistrant />
    </div>
  );
}
