import axios from "axios";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { Form, Modal } from "react-bootstrap-v5";
import { MemberApi } from "../../../../../setup/function/Api";
import { KTSVG } from "../../../../../_start/helpers";

type Props = {
  show: boolean;
  handleClose: () => void;
  id: number;
};

export default function ModalImport(props: Props) {
  const { show, handleClose } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const allowedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setSelectedFile(file || null);
  };

  async function importFile(file: File) {
    console.log("running import file func...");

    await MemberApi.ImportApi(props.id, file)
      .then((response) => {
        console.log("response");
        alert("Success mengimport file");
      })
      .catch((error) => {
        console.log("error", error.response);
        const { message } = error.response.data;
        console.log("message", message);
        // Handle API error
        if (axios.isAxiosError(error) && error) {
          setLoading(false);

          console.error("API error:", message);
          setLoading(false);

          alert(message);
        } else {
          console.error("Other error:", error);
          alert(message);
        }
      });
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedFile) {
      if (allowedFileTypes.includes(selectedFile.type)) {
        // Perform your file upload or processing logic here
        importFile(selectedFile);
        console.log("Selected file:", selectedFile);
      } else {
        // Handle the case when an invalid file type is selected
        alert("Tipe file wajib .xls , .xlsx ");

        console.log("Invalid file type. Please select an XLSX file.");
      }
    } else {
      // Handle the case when no file is selected
      alert("Mohon masukan file anda");
      console.log("No file selected.");
    }
  };

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered h-auto"
      show={show}
      onHide={handleClose}
      //   onEntered={loadStepper}
    >
      <div className="container px-10 py-10 ">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-light-primary"
            onClick={handleClose}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotone/Navigation/Close.svg"
            />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body">
          {/*begin::Stepper */}
          <div
            className="stepper stepper-1 d-flex flex-column flex-xl-row flex-row-fluid "
            id="kt_modal_create_app_stepper"
          >
            {/*begin::Aside */}
            {/*begin::Aside */}

            {/*begin::Content */}
            <div className="d-flex flex-row-fluid justify-content-center">
              {/*begin::Form */}
              <Form
                className="pb-5 w-100 w-md-400px w-xl-100"
                onSubmit={handleSubmit}
                id="kt_modal_create_app_form"
              >
                {/*begin::Step 1 */}
                <div className="pb-2 current" data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Heading */}
                    <div className="pb-5 pb-lg-10">
                      <h3 className="fw-bolder text-dark display-7">
                        Import Excel{" "}
                      </h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className="fv-row mb-12">
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Import excel file</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={handleFileChange}
                          isInvalid={!selectedFile}
                          accept=".xlsx, .xls, application/vnd.ms-excel"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please select an XLSX file.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    {/*end::Form Group */}

                    {/*begin::Form Group */}

                    {/* end::Section */}
                  </div>
                </div>
                {/*end::Step 5 */}

                {/*begin::Actions */}
                <div className="d-flex justify-content-between pt-2 w-100">
                  <div className="mr-2 w-100">
                    {loading !== true ? (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3 w-100"
                        data-kt-stepper-action="next"
                      >
                        Import Data{" "}
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-1"
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3 w-100"
                        data-kt-stepper-action="next"
                        disabled
                      >
                        Sedang melakukan import file.....
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-1"
                        />
                      </button>
                    )}
                  </div>
                </div>
                {/*end::Actions */}
              </Form>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
      </div>
    </Modal>
  );
}
