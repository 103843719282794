import axios from "axios";
import { AuthModel } from "../models/AuthModel";
import { UserModel } from "../models/UserModel";
import jwt from "jsonwebtoken";
import store from "../../../../setup/redux/Store";

const API_URL = process.env.REACT_APP_API_URL || "api";

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`;
export const LOGIN_URL = `${API_URL}/authentications/back-office`;
export const REGISTER_URL = `${API_URL}/auth/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`;

// Server should return AuthModel
export async function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email: email,
    password: password,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string
) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email });
}

export function getToken() {
  const localStorageData = JSON.parse(
    localStorage.getItem("persist:token") || "{}"
  );
  const tokenFromLocalStorage = localStorageData.token?.replace(/"/g, "");

  if (tokenFromLocalStorage) {
    console.log("getting from local");
    return tokenFromLocalStorage;
  }
  console.log("getting from state");

  const tokenFromState = store.getState()?.auth?.token as string;
  return tokenFromState;
}

export function* decodeUserByToken() {
  const token = store.getState()?.auth?.token as string;
  const user = jwt.decode(token) as unknown as UserModel;
  console.log("user data", user);
  return user;
}
