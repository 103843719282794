import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap-v5";
import { KTSVG } from "../../../../../_start/helpers";

import loadingIcon from "../../../../../assets/loading.png";

import "./ModalStyle.css";

// import "./SuccessModal.css";

type Props = {
  show: boolean;
  handleClose: () => void;
  setShowImportSuccess: (show: boolean) => void;
  setShowImportPreview: (show: boolean) => void;
  setShow: (show: boolean) => void;

  //   id: any;
};

export default function ModalLoading(props: Props) {
  const {
    show,
    handleClose,
    setShowImportSuccess,
    setShowImportPreview,
    setShow,
  } = props;

  // if (show === true) {
  //   setTimeout(() => {
  //     setShowImportSuccess(true);
  //     setShow(false);
  //     setShowImportPreview(false);
  //     handleClose();
  //   }, 2000);
  // }

  return (
    <>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog-centered h-auto"
        show={show}
        onHide={handleClose}
        //   onEntered={loadStepper}
      >
        <div className="container px-10 py-10 ">
          <div className="modal-body">
            {/*begin::Stepper */}
            <div
              className="stepper stepper-1 d-flex flex-column "
              id="kt_modal_create_app_stepper"
            >
              {/*begin::Aside */}
              {/*begin::Aside */}
              <img
                src={loadingIcon}
                className="loading_icon_create mx-auto"
                alt=""
              />
              {/*begin::Form */}
              <h3 className="text-center mt-3">
                Sinkornisasi data peserta....
              </h3>
              <p className="text-muted text-center">Harap menunggu....</p>
              {/*begin::Content */}
              {/*end::Content */}
            </div>
            {/* end::Stepper */}
          </div>
        </div>
      </Modal>
    </>
  );
}
